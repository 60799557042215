import Modal from '@components/Modal';
import { cn } from '@lib/utils';
import Image from 'next/image';
import React, { useEffect } from 'react';

import CatConventionalPlan from '@assets/images/cat-conventional-plan.svg';
import CatTakafulPlan from '@assets/images/cat-takaful-plan.svg';
import { ENABLE_TAKAFUL, PRODUCT_SLUG } from '@utils/constant';
import { i18n, useTranslation } from 'next-i18next';
import { useStore } from '@stores/store';

interface IProductChooserProps {
  typeOfPurchase: 'add-pet' | 'eligibility';
  opened: boolean;
  setOpened: (opened: boolean) => void;
  customCallback?: (chosenProduct: PRODUCT_SLUG) => void;
  title?: string;
  showConventional?: boolean;
  showTakaful?: boolean;
}

const ProductChooser: React.FC<IProductChooserProps> = ({
  typeOfPurchase,
  opened,
  setOpened,
  customCallback,
  title,
  showConventional = true,
  showTakaful = true,
}) => {
  const { t: tc } = useTranslation('common');
  const resetPetState = useStore((state) => state.resetPetState);

  const takafulEnabled = ENABLE_TAKAFUL;

  const conventionalLink =
    typeOfPurchase === 'add-pet' ? '/add-pet/v3' : '/eligibility/v4';

  useEffect(() => {
    resetPetState();
  }, []);

  const locale = i18n?.resolvedLanguage;
  const en = ['en', 'eni'].includes(locale || 'en');
  const navigatingLang = en ? 'eni' : 'myi';
  const resolvedNavigatingLang = ['en', 'my'].includes(locale || 'en')
    ? navigatingLang
    : locale;

  const takafulLink =
    typeOfPurchase === 'add-pet'
      ? `/${resolvedNavigatingLang}/add-pet/takaful/new`
      : `/${resolvedNavigatingLang}/eligibility/takaful`;

  return (
    <Modal
      onClose={() => setOpened(false)}
      opened={opened}
      title={title ? title : tc('get_covered')}
    >
      <div className="p-4">
        {showConventional ? (
          <div
            className={cn('pet-card mt-4 cursor-pointer flex flex-row ')}
            onClick={() => {
              customCallback
                ? customCallback(PRODUCT_SLUG.MSIG_CONVENTIONAL)
                : (window.location.href = conventionalLink);
            }}
          >
            <div className="flex-shrink-0 w-[60px] md:w-[80px] mr-3">
              <Image
                alt="Cat Conventional Plan"
                src={CatConventionalPlan}
                height={48}
                width={48}
                layout="responsive"
              />
            </div>

            <div className="flex flex-col flex-grow justify-center">
              <p className="text-lg font-bold">{tc('pet_insurance')}</p>
              <p className="text-md font-normal">{tc('get_covered_content')}</p>
            </div>
          </div>
        ) : null}

        {showTakaful && takafulEnabled ? (
          <div
            className={cn('pet-card mt-4 cursor-pointer flex flex-row ')}
            onClick={() => {
              customCallback
                ? customCallback(PRODUCT_SLUG.ZURICH_TAKAFUL)
                : (window.location.href = takafulLink);
            }}
          >
            <div className="flex-shrink-0 w-[60px] md:w-[80px] mr-3">
              <Image
                alt="Cat Takaful Plan"
                src={CatTakafulPlan}
                layout="responsive"
              />
            </div>

            <div className="flex flex-col flex-grow justify-center">
              <p className="text-lg font-bold">{tc('pet_takaful')}</p>
              <p className="text-md font-normal">{tc('pet_takaful_content')}</p>
            </div>
          </div>
        ) : null}
      </div>
    </Modal>
  );
};

export default ProductChooser;
