import * as z from 'zod';
import { ownerInfoFormSchema } from 'pages/eligibility/v4/owner-info';
import { ownerDetailsFormSchema } from 'pages/eligibility/v4/owner-details';
import { StateCreator } from 'zustand';
import { Store } from '@stores/store';
import { dayjs } from '@lib/dayjs';

interface OwnerSlice {
  owner: Partial<
    z.infer<typeof ownerInfoFormSchema & typeof ownerDetailsFormSchema>
  >;
  updateOwner: (owner: Partial<z.infer<typeof ownerInfoFormSchema>>) => void;
  updateOwnerDetails: (
    owner: Partial<z.infer<typeof ownerDetailsFormSchema>>
  ) => void;
}

const createOwnerSlice: StateCreator<Store, [], [], OwnerSlice> = (set) => {
  return {
    owner: {
      dob: dayjs().subtract(18, 'years').toDate(),
    },
    updateOwner: (owner: Partial<z.infer<typeof ownerInfoFormSchema>>) =>
      set((state) => ({ owner: { ...state.owner, ...owner } })),
    updateOwnerDetails: (
      owner: Partial<z.infer<typeof ownerDetailsFormSchema>>
    ) => set((state) => ({ owner: { ...state.owner, ...owner } })),
  };
};

export { type OwnerSlice, createOwnerSlice };
