import IMG_CATBASIC from '@assets/images/cat-basic.svg';
import IMG_CATCHAMPION from '@assets/images/cat-champion.svg';
import IMG_CATPLUS from '@assets/images/cat-plus.svg';
import IMG_DOGBASIC from '@assets/images/dog-basic.svg';
import IMG_DOGCHAMPION from '@assets/images/dog-champion.svg';
import IMG_DOGPLUS from '@assets/images/dog-plus.svg';
import V3CatIcon from '@assets/images/v3-cat-icon.svg';
import V3DogIcon from '@assets/images/v3-dog-icon.svg';

export const V3_PET_ICONS = {
  CAT: V3CatIcon,
  DOG: V3DogIcon,
};

export const DISABLE_WORRY_FREE_RENEWAL_CHECKBOX =
  process.env.NEXT_PUBLIC_DISABLE_WORRY_FREE_RENEWAL_CHECKBOX === 'true';

export const COUNTRY_CODE = 'MY';
export const CURRENCY = 'MYR';

export const ENABLE_8_PERC_SST =
  process.env.NEXT_PUBLIC_ENABLE_8_PERC_SST === 'true';
export const ENABLE_SERVICE_FEE =
  process.env.NEXT_PUBLIC_ENABLE_SERVICE_FEE === 'true';
export const SERVICE_FEE_MONTHLY_PERCENTAGE = 0.03;
export const SERVICE_FEE_YEARLY_PERCENTAGE = 0.02;
export const SERVICE_FEE_EFFECTIVE_DATE =
  process.env.NEXT_PUBLIC_SERVICE_FEE_EFFECTIVE_DATE || '2024-03-01';
export const ENABLE_APM = process.env.NEXT_PUBLIC_ENABLE_APM === 'true';

export const AGE_BREED_EFFECTIVE_DATE =
  process.env.NEXT_PUBLIC_AGE_BREED_EFFECTIVE_DATE || '2024-04-03';

export const AGE_BREED_START_DATE =
  process.env.NEXT_PUBLIC_AGE_BREED_START_DATE || '2024-05-03';

export const ENABLE_TAKAFUL = process.env.NEXT_PUBLIC_ENABLE_TAKAFUL === 'true';
export const ENABLE_AGE_BREED_ELIGIBILITY =
  process.env.NEXT_PUBLIC_ENABLE_AGE_BREED_ELIGIBILITY === 'true';
export const ENABLE_AGE_BREED_ADD_PET =
  process.env.NEXT_PUBLIC_ENABLE_AGE_BREED_ADD_PET === 'true';
export const NEXT_PUBLIC_ENABLE_AGE_BREED_ELI =
  process.env.NEXT_PUBLIC_ENABLE_AGE_BREED_ELI === 'true';
export const ENABLE_AGE_BREED_BUYBACK =
  process.env.NEXT_PUBLIC_ENABLE_AGE_BREED_BUYBACK === 'true';

export const PROXY_API_URL = process.env.PROXY_API_URL || '';
export const ZURICH_CLAIMS_API_STRING_LIMIT =
  process.env.ZURICH_CLAIMS_API_STRING_LIMIT === 'true';

export const ENABLE_ZURICH_CLAIMS_REQUEST_ERROR_SLACK =
  process.env.ENABLE_ZURICH_CLAIMS_REQUEST_ERROR_SLACK === 'true';
export const ENABLE_ZURICH_CLAIMS_NOTIFICATION_SLACK =
  process.env.ENABLE_ZURICH_CLAIMS_NOTIFICATION_SLACK === 'true';
export const ENABLE_ZURICH_CLAIMS_NULL_DATA_SLACK =
  process.env.ENABLE_ZURICH_CLAIMS_NULL_DATA_SLACK === 'true';

export const ENABLE_INCREASE_LIMIT =
  process.env.NEXT_PUBLIC_ENABLE_INCREASE_LIMIT === 'true';

// 8% GST Tax & RM10 Stamp Duty (Integer - 1000 / 100)
export const TAX_PERCENTAGE = ENABLE_8_PERC_SST ? 0.08 : 0.06;
export const GROUP_STAMP_DUTY = 1000;
export const GROUP_DISCOUNT_PERCENTAGE = 0.1;
export const UPLOAD_LIMIT = 5;
export const DEDUCTIBLE_3RD_PARTY_CAT_DOG_PLUS_CHAMPION = 1000; // CAT BASIC NTG
export const DEDUCTIBLE_3RD_PARTY_DOG_BASIC = 500;

export const RENEW_DISCOUNT_PERCENTAGE = 0.2;
export const NEW_DISCOUNT_PERCENTAGE = 0.28;

// retry collection constants
export const CURLEC_COLLECTION_MAX_RETRY = 3;
export const CURLEC_COLLECTION_DAYS_BETWEEN_RETRIES = 7;

export const SUBSCRIPTION_ENV =
  process.env.NEXT_PUBLIC_SUBSCRIPTION_ENV || 'test';

export enum PRODUCT_SLUG {
  MSIG_CONVENTIONAL = 'msig-conventional',
  ZURICH_TAKAFUL = 'zurich-takaful',
}

export const DELAYED_ACTIVATION = [PRODUCT_SLUG.ZURICH_TAKAFUL];

export enum ID_TYPE {
  NRIC = 'nric',
  PASSPORT = 'passport',
}

export enum CURLEC_IDTYPE {
  NRIC = 'NRIC',
  PASSPORT = 'PASSPORT_NUMBER',
}

export const EMAIL_EXCLUDED_FROM_DRIPS = ['@foodpanda.my'];

export const ELIGIBLE_CAT_BREED = {
  ABYSSINIAN_CAT: 'Abyssinian Cat',
  AEGEAN_CAT: 'Aegean Cat',
  AMERICAN_BOBTAIL: 'American Bobtail',
  AMERICAN_CURL: 'American Curl',
  AMERICAN_SHORTHAIR: 'American Shorthair',
  AMERICAN_WIREHAIR: 'American Wirehair',
  ARABIAN_MAU: 'Arabian Mau',
  ASIAN_CAT: 'Asian Cat',
  ASIAN_SEMI_LONGHAIR: 'Asian Semi-longhair',
  AUSTRALIAN_MIST: 'Australian Mist',
  BALINESE_CAT: 'Balinese Cat',
  BAMBINO_CAT: 'Bambino Cat',
  BENGAL_CAT: 'Bengal Cat',
  BIRMAN: 'Birman',
  BOMBAY_CAT: 'Bombay Cat',
  BRAZILIAN_SHORTHAIR: 'Brazilian Shorthair',
  BRITISH_LONGHAIR: 'British Longhair',
  BRITISH_SHORTHAIR: 'British Shorthair',
  BURMESE_CAT: 'Burmese Cat',
  BURMILA: 'Burmila',
  CALIFORNIA_SPANGLED: 'California Spangled',
  CHANTILLY_TIFFANY: 'Chantilly-Tiffany',
  CHARTREUX: 'Chartreux',
  CHAUSIE: 'Chausie',
  CHEETOH_CAT: 'Cheetoh Cat',
  COLORPOINT_SHORTHAIR: 'Colorpoint Shorthair',
  CORNISH_REX: 'Cornish Rex',
  CYMRIC_CAT_MANX_LONGHAIR: 'Cymric Cat / Manx Longhair',
  CYPRUS_CAT: 'Cyprus Cat',
  DEVON_REX: 'Devon Rex',
  DONSKOY_CAT_DON_SPHYNX: 'Donskoy Cat / Don Sphynx',
  DRAGON_LI: 'Dragon Li',
  DWARF_CAT_DWELF: 'Dwarf Cat / Dwelf',
  EGYPTIAN_MAU: 'Egyptian Mau',
  EUROPEAN_SHORTHAIR: 'European Shorthair',
  EXOTIC_SHORTHAIR: 'Exotic Shorthair',
  FOLDEX_CAT: 'Foldex Cat',
  GERMAN_REX: 'German Rex',
  HAVANA_BROWN: 'Havana Brown',
  HIGHLANDER_CAT: 'Highlander Cat',
  HIMALAYAN_CAT: 'Himalayan Cat',
  JAPANESE_BOBTAIL: 'Japanese Bobtail',
  JAVANESE_CAT: 'Javanese Cat',
  KUCING_KAMPUNG: 'Kucing Kampung',
  KHAO_MANEE: 'Khao Manee',
  KORAT: 'Korat',
  KOREAN_BOBTAIL: 'Korean Bobtail',
  KORN_JA: 'Korn Ja',
  KURILIAN_BOBTAIL: 'Kurilian Bobtail',
  LAPERM: 'LaPerm',
  LYKOI: 'Lykoi',
  MAINE_COON: 'Maine Coon',
  MANX_CAT: 'Manx Cat',
  MEKONG_BOBTAIL: 'Mekong Bobtail',
  MINSKIN: 'Minskin',
  MUNCHKIN_CAT: 'Munchkin Cat',
  NAPOLEON_CAT: 'Napoleon Cat',
  NEBULUNG: 'Nebulung',
  NORWEGIAN_FOREST_CAT: 'Norwegian Forest Cat',
  OCICAT: 'OciCat',
  OJOS_AZULES: 'Ojos Azules',
  OREGON_REX: 'Oregon Rex',
  ORIENTAL_BICOLOR: 'Oriental Bicolor',
  ORIENTAL_LONGHAIR: 'Oriental Longhair',
  ORIENTAL_SHORTHAIR: 'Oriental Shorthair',
  PERFOLD_CAT: 'Perfold Cat',
  PERSIAN_CAT: 'Persian Cat',
  PETERBALD: 'Peterbald',
  'PIXIE-BOB': 'Pixie-bob',
  RAAS_CAT: 'Raas Cat',
  RAGAMUFFIN_CAT: 'Ragamuffin Cat',
  RAGDOLL: 'Ragdoll',
  RUSSIAN_BLUE: 'Russian Blue',
  RUSSIAN_WHITE_BLACK_TABBY: 'Russian White/Black/Tabby',
  SAM_SAWET: 'Sam Sawet',
  SAVANNAH_CAT: 'Savannah Cat',
  SCOTTISH_FOLD: 'Scottish Fold',
  SELKIRK_REX: 'Selkirk Rex',
  SERENGETI_CAT: 'Serengeti Cat',
  SERRADE_PETIT_CAT: 'Serrade Petit Cat',
  SIAMESE_CAT: 'Siamese Cat',
  SIBERIAN_CAT: 'Siberian Cat',
  SINGAPURA_CAT: 'Singapura Cat',
  SNOWSHOE_CAT: 'Snowshoe Cat',
  SOKOKE: 'Sokoke',
  SOMALI_CAT: 'Somali Cat',
  SPHYNX_CAT: 'Sphynx Cat',
  SUPHALAK: 'Suphalak',
  THAI_CAT: 'Thai Cat',
  TONKINESE_CAT: 'Tonkinese Cat',
  TOYGER: 'Toyger',
  TRADITIONAL_PERSIAN_CAT: 'Traditional Persian Cat',
  TURKISH_ANGORA: 'Turkish Angora',
  TURKISH_VAN: 'Turkish Van',
  UKRAINIAN_LEVKOY: 'Ukrainian Levkoy',
  'MIXED/_CROSS_BREED_CAT': 'Mixed/ Cross Breed Cat',
  'DOMESTIC_SHORT-HAIRED': 'Domestic Short-haired',
  'DOMESTIC_LONG-HAIRED': 'Domestic Long-haired',
};

export const ELIGIBLE_DOG_BREED = {
  AFFENPINSCHER: 'Affenpinscher',
  AFGHAN_HOUND: 'Afghan Hound',
  AIREDALE_TERRIER: 'Airedale Terrier',
  ALASKAN_MALAMUTE: 'Alaskan Malamute',
  AMERICAN_ENGLISH_COONHOUND: 'American English Coonhound',
  AMERICAN_ESKIMO_DOG: 'American Eskimo Dog',
  AMERICAN_FOXHOUND: 'American Foxhound',
  AMERICAN_HAIRLESS_TERRIER: 'American Hairless Terrier',
  AMERICAN_LEOPARD_HOUND: 'American Leopard Hound',
  AMERICAN_WATER_SPANIEL: 'American Water Spaniel',
  ANATOLIAN_SHEPHERD_DOG: 'Anatolian Shepherd Dog',
  APPENZELLER_SENNENHUNDE: 'Appenzeller Sennenhunde',
  AUSTRALIAN_CATTLE_DOG: 'Australian Cattle Dog',
  AUSTRALIAN_SHEPHERD: 'Australian Shepherd',
  AUSTRALIAN_TERRIER: 'Australian Terrier',
  AZAWAKH: 'Azawakh',
  BARBET: 'Barbet',
  BASENJI: 'Basenji',
  BASSET_FAUVE_DE_BRETAGNE: 'Basset Fauve De Bretagne',
  BASSET_HOUND: 'Basset Hound',
  BEAGLE: 'Beagle',
  BEARDED_COLLIE: 'Bearded Collie',
  BEAUCERON: 'Beauceron',
  BEDLINGTON_TERRIER: 'Bedlington Terrier',
  BELGIAN_LAEKENOIS: 'Belgian Laekenois',
  BELGIAN_MALINOIS: 'Belgian Malinois',
  BELGIAN_SHEEPDOG: 'Belgian SheepDog',
  BELGIAN_TERVUREN: 'Belgian Tervuren',
  BERGAMASCO: 'Bergamasco',
  BERGER_PICARD: 'Berger Picard',
  BERNESE_MOUNTAIN_DOG: 'Bernese Mountain Dog',
  BICHON_FRISE: 'Bichon Frise',
  BIEWER_TERRIER: 'Biewer Terrier',
  BLACK_AND_TAN_COONHOUND: 'Black and Tan Coonhound',
  BLACK_RUSSIAN_TERRIER: 'Black Russian Terrier',
  BLOODHOUND: 'Bloodhound',
  BLUETICK_COONHOUND: 'Bluetick Coonhound',
  BOERBOEL: 'Boerboel',
  BOLOGNESE: 'Bolognese',
  BORDER_COLLIE: 'Border Collie',
  BORDER_TERRIER: 'Border Terrier',
  BORZOI: 'Borzoi',
  BOSTON_TERRIER: 'Boston Terrier',
  BOUVIER_DES_FLANDRES: 'Bouvier des Flandres',
  BOXER: 'Boxer',
  BOYKIN_SPANIEL: 'Boykin Spaniel',
  BRACCO_ITALIANO: 'Bracco Italiano',
  BRAQUE_DU_BOURBONNAIS: 'Braque Du Bourbonnais',
  BRIARD: 'Briard',
  BRITTANY: 'Brittany',
  BRUSSELS_GRIFFON: 'Brussels Griffon',
  CAIRN_TERRIER: 'Cairn Terrier',
  CANAAN_DOG: 'Canaan Dog',
  CANE_CORSO: 'Cane Corso',
  CARDIGAN_WELSH_CORGI: 'Cardigan Welsh Corgi',
  CATAHOULA_LEOPARD_DOG: 'Catahoula Leopard Dog',
  CAUCASIAN_OVCHARKA: 'Caucasian Ovcharka',
  CAVALIER_KING_CHARLES_SPANIEL: 'Cavalier King Charles Spaniel',
  CENTRAL_ASIAN_SHEPHERD_DOG: 'Central Asian Shepherd Dog',
  CESKY_TERRIER: 'Cesky Terrier',
  CHESAPEAKE_BAY_RETRIEVER: 'Chesapeake Bay Retriever',
  CHIHUAHUA: 'Chihuahua',
  CHINESE_CRESTED: 'Chinese Crested',
  'CHINESE_SHAR-PEI': 'Chinese Shar-Pei',
  CHINOOK: 'Chinook',
  CHOW_CHOW: 'Chow Chow',
  CIRNECO_DELL_ETNA: "Cirneco Dell'Etna",
  CLUMBER_SPANIEL: 'Clumber Spaniel',
  COCKER_SPANIEL: 'Cocker Spaniel',
  COLLIE: 'Collie',
  COTON_DE_TULEAR: 'Coton de Tulear',
  'CURLY-COATED_RETRIEVER': 'Curly-Coated Retriever',
  CZECHOSLOVAKIAN_VLCAK: 'Czechoslovakian VLCAK',
  DACHSHUND: 'Dachshund',
  DALMATIAN: 'Dalmatian',
  DANDIE_DINMONT_TERRIER: 'Dandie Dinmont Terrier',
  'DANISH-SWEDISH_FARMDOG': 'Danish-Swedish FarmDog',
  DEUTSCHER_WACHTELHUND: 'Deutscher Wachtelhund',
  DOGUE_DE_BORDEAUX: 'Dogue de Bordeaux',
  DRENTSCHE_PATRIJSHOND: 'Drentsche Patrijshond',
  DREVER: 'Drever',
  DUTCH_SHEPHERD: 'Dutch Shepherd',
  ENGLISH_COCKER_SPANIEL: 'English Cocker Spaniel',
  ENGLISH_FOXHOUND: 'English Foxhound',
  ENGLISH_SETTER: 'English Setter',
  ENGLISH_SPRINGER_SPANIEL: 'English Springer Spaniel',
  ENGLISH_TOY_SPANIEL: 'English Toy Spaniel',
  ENTLEBUCHER_MOUNTAIN_DOG: 'Entlebucher Mountain Dog',
  ESTRELA_MOUNTAIN_DOG: 'Estrela Mountain Dog',
  EURASIER: 'Eurasier',
  FIELD_SPANIEL: 'Field Spaniel',
  FINNISH_LAPPHUND: 'Finnish Lapphund',
  FINNISH_SPITZ: 'Finnish Spitz',
  'FLAT-COATED_RETRIEVER': 'Flat-Coated Retriever',
  FRENCH_BULLDOG: 'French BullDog',
  FRENCH_SPANIEL: 'French Spaniel',
  GERMAN_LONGHAIRED_POINTER: 'German Longhaired Pointer',
  GERMAN_PINSCHER: 'German Pinscher',
  GERMAN_SHORTHAIRED_POINTER: 'German Shorthaired Pointer',
  GERMAN_SPITZ: 'German Spitz',
  GERMAN_WIREHAIRED_POINTER: 'German Wirehaired Pointer',
  GIANT_SCHNAUZER: 'Giant Schnauzer',
  GLEN_OF_IMAAL_TERRIER: 'Glen of Imaal Terrier',
  GOLDEN_RETRIEVER: 'Golden Retriever',
  GORDON_SETTER: 'Gordon Setter',
  GRAND_BASSET_GRIFFON_VENDEEN: 'Grand Basset Griffon Vendeen',
  GREAT_DANE: 'Great Dane',
  GREAT_PYRENEES: 'Great Pyrenees',
  GREATER_SWISS_MOUNTAIN_DOG: 'Greater Swiss Mountain Dog',
  GREYHOUND: 'Greyhound',
  HAMILTONSTOVARE: 'Hamiltonstovare',
  HARRIER: 'Harrier',
  HAVANESE: 'Havanese',
  HOVAWART: 'Hovawart',
  IBIZAN_HOUND: 'Ibizan Hound',
  ICELANDIC_SHEEPDOG: 'Icelandic SheepDog',
  IRISH_RED_AND_WHITE_SETTER: 'Irish Red and White Setter',
  IRISH_SETTER: 'Irish Setter',
  IRISH_TERRIER: 'Irish Terrier',
  IRISH_WATER_SPANIEL: 'Irish Water Spaniel',
  IRISH_WOLFHOUND: 'Irish Wolfhound',
  ITALIAN_GREYHOUND: 'Italian Greyhound',
  JACK_RUSSELL_TERRIER: 'Jack Russell Terrier',
  JAGDTERRIER: 'Jagdterrier',
  JAPANESE_CHIN: 'Japanese Chin',
  JINDO: 'Jindo',
  KAI_KEN: 'Kai Ken',
  KARELIAN_BEAR_DOG: 'Karelian Bear Dog',
  KEESHOND: 'Keeshond',
  KERRY_BLUE_TERRIER: 'Kerry Blue Terrier',
  KISHU_KEN: 'Kishu Ken',
  KOMONDOR: 'Komondor',
  KROMFOHRLANDER: 'Kromfohrlander',
  KUVASZ: 'Kuvasz',
  LABRADOR_RETRIEVER: 'Labrador Retriever',
  LAGOTTO_ROMAGNOLO: 'Lagotto Romagnolo',
  LAKELAND_TERRIER: 'Lakeland Terrier',
  LANCASHIRE_HEELER: 'Lancashire Heeler',
  LEONBERGER: 'Leonberger',
  LHASA_APSO: 'Lhasa Apso',
  LOWCHEN: 'Lowchen',
  MALTESE: 'Maltese',
  MANCHESTER_TERRIER: 'Manchester Terrier',
  MINIATURE_AMERICAN_SHEPHERD: 'Miniature American Shepherd',
  MINIATURE_BULL_TERRIER: 'Miniature Bull Terrier',
  MINIATURE_PINSCHER: 'Miniature Pinscher',
  MINIATURE_SCHNAUZER: 'Miniature Schnauzer',
  MUDI: 'Mudi',
  NEDERLANDSE_KOOIKERHONDJE: 'Nederlandse Kooikerhondje',
  NEWFOUNDLAND: 'Newfoundland',
  NORFOLK_TERRIER: 'Norfolk Terrier',
  NORRBOTTENSPETS: 'Norrbottenspets',
  NORWEGIAN_BUHUND: 'Norwegian Buhund',
  NORWEGIAN_ELKHOUND: 'Norwegian Elkhound',
  NORWEGIAN_LUNDEHUND: 'Norwegian Lundehund',
  NORWICH_TERRIER: 'Norwich Terrier',
  NOVA_SCOTIA_DUCK_TOLLING_RETRIEVER: 'Nova Scotia Duck Tolling Retriever',
  OLD_ENGLISH_SHEEPDOG: 'Old English SheepDog',
  OTTERHOUND: 'Otterhound',
  PAPILLON: 'Papillon',
  PARSON_RUSSELL_TERRIER: 'Parson Russell Terrier',
  PEKINGESE: 'Pekingese',
  PEMBROKE_WELSH_CORGI: 'Pembroke Welsh Corgi',
  PERUVIAN_INCA_ORCHID: 'Peruvian Inca Orchid',
  PETIT_BASSET_GRIFFON_VENDEEN: 'Petit Basset Griffon Vendeen',
  PHARAOH_HOUND: 'Pharaoh Hound',
  PLOTT: 'Plott',
  POINTER: 'Pointer',
  POLISH_LOWLAND_SHEEPDOG: 'Polish Lowland SheepDog',
  POMERANIAN: 'Pomeranian',
  POODLE: 'Poodle',
  PORTUGESE_PODENGO: 'Portugese Podengo',
  PORTUGESE_PODENGO_PEQUENO: 'Portugese Podengo Pequeno',
  PORTUGESE_POINTER: 'Portugese Pointer',
  PORTUGESE_SHEEPDOG: 'Portugese SheepDog',
  PORTUGUESE_WATER_DOG: 'Portuguese Water Dog',
  PUG: 'Pug',
  PULI: 'Puli',
  PUMI: 'Pumi',
  PYRENEAN_MASTIFF: 'Pyrenean Mastiff',
  PYRENEAN_SHEPHERD: 'Pyrenean Shepherd',
  RAFEIRO_DO_ALENTEJO: 'Rafeiro Do Alentejo',
  RAT_TERRIER: 'Rat Terrier',
  REDBONE_COONHOUND: 'Redbone Coonhound',
  RHODESIAN_RIDGEBACK: 'Rhodesian Ridgeback',
  RUSSELL_TERRIER: 'Russell Terrier',
  RUSSIAN_TOY: 'Russian Toy',
  RUSSIAN_TSVETNAYA_BOLONKA: 'Russian Tsvetnaya Bolonka',
  SALUKI: 'Saluki',
  SAMOYED: 'Samoyed',
  SCHAPENDOES: 'Schapendoes',
  SCHIPPERKE: 'Schipperke',
  SCOTTISH_DEERHOUND: 'Scottish Deerhound',
  SCOTTISH_TERRIER: 'Scottish Terrier',
  SEALYHAM_TERRIER: 'Sealyham Terrier',
  SHETLAND_SHEEPDOG: 'Shetland SheepDog',
  SHIBA_INU: 'Shiba Inu',
  SHIH_TZU: 'Shih Tzu',
  SHIKOKU: 'Shikoku',
  SIBERIAN_HUSKY: 'Siberian Husky',
  SILKY_TERRIER: 'Silky Terrier',
  SKYE_TERRIER: 'Skye Terrier',
  SLOUGHI: 'Sloughi',
  SLOVENSKY_CUVAC: 'Slovensky Cuvac',
  SLOVENSKY_KOPOV: 'Slovensky Kopov',
  SMALL_MUNSTERLANDER_POINTER: 'Small Munsterlander Pointer',
  SMOOTH_FOX_TERRIER: 'Smooth Fox Terrier',
  SOFT_COATED_WHEATEN_TERRIER: 'Soft Coated Wheaten Terrier',
  SPANISH_MASTIFF: 'Spanish Mastiff',
  SPANISH_WATER_DOG: 'Spanish Water Dog',
  SPINONE_ITALIANO: 'Spinone Italiano',
  ST_BERNARD: 'St Bernard',
  STABYHOUN: 'Stabyhoun',
  STANDARD_SCHNAUZER: 'Standard Schnauzer',
  SUSSEX_SPANIEL: 'Sussex Spaniel',
  SWEDISH_LAPPHUND: 'Swedish Lapphund',
  SWEDISH_VALLHUND: 'Swedish Vallhund',
  THAI_RIDGEBACK: 'Thai Ridgeback',
  TIBETAN_MASTIFF: 'Tibetan Mastiff',
  TIBETAN_SPANIEL: 'Tibetan Spaniel',
  TIBETAN_TERRIER: 'Tibetan Terrier',
  TORNJAK: 'Tornjak',
  TOY_FOX_TERRIER: 'Toy Fox Terrier',
  TRANSYLVANIAN_HOUND: 'Transylvanian Hound',
  TREEING_TENNESSEE_BRINDLE: 'Treeing Tennessee Brindle',
  TREEING_WALKER_COONHOUND: 'Treeing Walker Coonhound',
  VIZSLA: 'Vizsla',
  WEIMARANER: 'Weimaraner',
  WELSH_SPRINGER_SPANIEL: 'Welsh Springer Spaniel',
  WELSH_TERRIER: 'Welsh Terrier',
  WEST_HIGHLAND_WHITE_TERRIER: 'West Highland White Terrier',
  WHIPPET: 'Whippet',
  WIRE_FOX_TERRIER: 'Wire Fox Terrier',
  WIREHAIRED_POINTING_GRIFFON: 'Wirehaired Pointing Griffon',
  WIREHAIRED_VIZSLA: 'Wirehaired Vizsla',
  WORKING_KELPIE: 'Working Kelpie',
  XOLOITZCUINTLI: 'Xoloitzcuintli',
  YORKSHIRE_TERRIER: 'Yorkshire Terrier',
  JAPANESE_SPITZ: 'Japanese Spitz',
  ENGLISH_BULLDOG: 'English Bulldog',
  'MIXED/_CROSS_BREED_DOG': 'Mixed/ Cross Breed Dog',
  LOCAL_BREED: 'Local Breed',
  MONGREL: 'Mongrel',
  GERMAN_SHEPHERD: 'German Shepherd/Alsatian',
  ROTTWEILER: 'Rottweiler',
  DOBERMAN: 'Doberman',
};

export const BANNED_DOG_BREED = {
  BULL_MASTIFF: 'Bull Mastiff',
  BULL_TERRIER: 'Bull Terrier',
  BELGIAN_SHEPHERD: 'Belgian Shepherd',
  EAST_EUROPEAN_SHEPHERD: 'East European Shepherd',
  CANARY_DOG: 'Canary Dog',
  PERRO_DE_PRESA_CANARIO: 'Perro de Presa Canario',
  AKITA: 'Akita',
  AMERICAN_BULLDOG: 'American Bulldog',
  DOGO_ARGENTINO: 'Dogo Argentino',
  FILA_BRAZILIERO: 'Fila Braziliero',
  JAPANESE_TOSA: 'Japanese Tosa',
  NEAPOLITAN_MASTIFF: 'Neapolitan Mastiff',
  PIT_BULL_TERRIER: 'Pit Bull Terrier',
  PIT_BULL: 'Pit Bull',
  AMERICAN_STAFFORDSHIRE_TERRIER: 'American Staffordshire Terrier',
  STAFFORDSHIRE_BULL_TERRIER: 'Staffordshire Bull Terrier',
  BROHOLMER: 'Broholmer',
};

export const POPULAR_BREED_COUNT = 5;

export const BANNED_CLAIMS_TYPES_BASED_ON_BREED = {
  ThirdPartyInjury: ['Doberman', 'German Shepherd/Alsatian', 'Rottweiler'],
};

export const ELIGIBILITY_CODE = {
  ELIGIBLE: 'ELIGIBLE',
  NOT_ELIGIBLE_MICROCHIP: 'NOT_ELIGIBLE_MICROCHIP',
  NOT_ELIGIBLE_AGE_UNDER: 'NOT_ELIGIBLE_AGE_UNDER',
  NOT_ELIGIBLE_AGE_OVER: 'NOT_ELIGIBLE_AGE_OVER',
  NOT_ELIGIBLE_BANNED_BREED: 'NOT_ELIGIBLE_BANNED_BREED',
  NOT_ELIGIBLE_BEHAVIOUR: 'NOT_ELIGIBLE_BEHAVIOUR',
  NOT_ELIGIBLE_RESIDENCE: 'NOT_ELIGIBLE_RESIDENCE',
  NOT_ELIGIBLE_PHYSICAL: 'NOT_ELIGIBLE_PHYSICAL',
  NOT_ELIGIBLE_VACCINE: 'NOT_ELIGIBLE_VACCINE',
};

export const OYEN_FAQ = {
  ROOT: 'https://www.oyen.my/knowledge-base',
  PET_AGE: 'https://help.oyen.my/en/what-are-the-criteria-to-enrol-my-pet',
  PET_RESIDENCE:
    'https://help.oyen.my/en/can-i-buy-a-pet-insurance-for-someone-else-or-for-a-stray-cat-or-dog',
  DOG_MICROCHIP:
    'https://help.oyen.my/en/why-do-i-have-to-get-microchip-for-my-pet-dog-to-qualify-for-insurance',
  CLAIM_LIMIT_UP_DOWNGRADE:
    'https://help.oyen.my/en/can-i-upgrade-or-downgrade-my-plan-after-i-have-purchased-the-policy?hs_preview=xXmRaPjU-53017484934',
  DEDUCTIBLE: 'https://help.oyen.my/en/what-is-deductibles',
  DEDUCTIBLES_POLICY_OVERVIEW:
    'https://help.oyen.my/en/what-if-i-have-both-co-payment-and-deductibles',
  UPGRADE_LIMIT:
    'https://help.oyen.my/en/can-i-upgrade-or-downgrade-my-plan-after-i-have-purchased-the-policy',
  CO_PAYMENT: 'https://help.oyen.my/en/what-is-a-co-payment',
  RENEWAL:
    'https://help.oyen.my/en/can-i-renew-my-pet-insurance-policy-upon-1-year-expiry-will-my-premium-increase',
};

export const SPEAK_TO_US_LINK =
  'https://api.whatsapp.com/send/?phone=60162996840&text=Hi%2C+my+payment+is+overdue%2C+please+assist&type=phone_number&app_absent=0';
export const SPEAK_TO_US_INELIGIBLE =
  'https://api.whatsapp.com/send/?phone=60162996840&text=Hi%2C+my+pet+is+ineligible%2C+please+help&type=phone_number&app_absent=0';
export const SPEAK_TO_US_CANCEL_BUT_CLAIMED =
  'https://api.whatsapp.com/send/?phone=60162996840&text=I%20want%20to%20cancel%20plan%20but%20claimed%20before%20:(';
export const SPEAK_TO_US_FULL_SETTLEMENT =
  'https://api.whatsapp.com/send/?phone=60162996840&text=Hi%2C%20I%20have%20a%20question%20regarding%20full%20settlement';
export const SPEAK_TO_US_CLAIMS_VALIDATION =
  'https://api.whatsapp.com/send/?phone=60162996840&text=Hi%2C%20I%20have%20a%20question%20regarding%20claims%20submission';
export const SPEAK_TO_US_FIND_CLAIMS =
  'https://api.whatsapp.com/send/?phone=60162996840&text=Hi%2C%20I%20have%20issues%20finding%20my%20claim.';
export const SPEAK_TO_US_PARTIAL_CANCELLATION =
  'https://api.whatsapp.com/send/?phone=60162996840&text=Im%20interested%20in%20participating%20takaful%20from%20my%20previous%20cancelled%20policy';
export const WHATSAPP_FLOATING_BUTTON_LINK =
  'https://api.whatsapp.com/send/?phone=60162996840&text=I+have+a+question+(existing+customer)&type=phone_number&app_absent=0';
export const SPEAK_TO_US_RENEWABLE_DEDUCTIBLE =
  'https://api.whatsapp.com/send/?phone=60162996840&text=I+have+a+question+about+my+renewal+deductibles&type=phone_number&app_absent=0';
export const SPEAK_TO_US_RENEWAL =
  'https://api.whatsapp.com/send/?phone=60162996840&text=I+have+questions+about+renewals&type=phone_number&app_absent=0';
export const FUSS_FREE_RENEWAL_TERMS_OF_USE =
  'https://www.oyen.my/fuss-free-renewal-terms-of-use';
export const OYEN_LANDING_PAGE = 'https://oyen.my';
export const OYEN_ORIGIN = process.env.NEXT_PUBLIC_APP_URL;

export const CAT_PLANS = {
  CAT_BASIC: 'Cat Basic',
  CAT_PLUS: 'Cat Plus',
  CAT_CHAMPION: 'Cat Champion',
};

export const DOG_PLANS = {
  DOG_BASIC: 'Dog Basic',
  DOG_PLUS: 'Dog Plus',
  DOG_CHAMPION: 'Dog Champion',
};

export const TAK_PLANS = {
  CAT_BASIC: 'Basic Yearly Takaful',
  CAT_PLUS: 'Plus Yearly Takaful',
  CAT_CHAMPION: 'Champion Yearly Takaful',
};

export enum PLAN_BILL_CYCLE {
  YEARLY = 'Yearly',
  MONTHLY = 'Monthly',
}

export enum PLAN_TYPE {
  PLAN,
  RENEWAL,
}

export enum VET_SUB_INFO {
  UPGRADE_LIMIT,
  DEDUCTIBLE,
  CO_PAYMENT,
}

export const PAYMENT_STATUS = {
  PAID: 'Paid',
  OVERDUE: 'Overdue',
  CARD_NEAR_EXPIRY: 'Card near expiry',
};

export const POLICY_STATUS = {
  PENDING: 'Pending',
  ACTIVE: 'Active',
  CANCELLED: 'Cancelled',
  PENDING_CANCEL: 'Pending - Cancel',
  RENEWED: 'Renewed',
  EXPIRED: 'Expired',
  INACTIVE: 'Inactive',
};

export const PLAN_IMAGE: Record<string, any> = {
  'cat basic': IMG_CATBASIC,
  'cat plus': IMG_CATPLUS,
  'cat champion': IMG_CATCHAMPION,
  'dog basic': IMG_DOGBASIC,
  'dog plus': IMG_DOGPLUS,
  'dog champion': IMG_DOGCHAMPION,
};

export const LANGUAGES = {
  en: {
    label: 'EN',
    value: 'en',
  },
  ms: {
    label: 'BM',
    value: 'my',
  },
  // zh_hans: {
  //   label: '中文',
  //   value: 'zh',
  // },
  en_i: {
    label: 'EN',
    value: 'eni',
  },
  ms_i: {
    label: 'BM',
    value: 'myi',
  },
  // zh_i: {
  //   label: '中文',
  //   value: 'zhi',
  // },
};

export enum Steps {
  CLAIM_TYPES,
  CHOOSE_CLAIM,
  VET_INFO,
  UPLOAD_BILL,
  UPLOAD_DIAGNOSIS,
  DIAGNOSIS,
  CLAIM_SUMMARY,
  CLAIM_SUCCESS,
}

export enum CARD_TYPE {
  VISA = 'VISA',
  MASTERCARD = 'MASTERCARD',
}

export enum CRON_STATUS {
  Pending = 0,
  Done = 1,
  Cancelled = 9,
  Max = 15,
}

export enum IllnessChronic {
  NONE = 'NONE',
  CHRONIC = 'CHRONIC',
}

export enum RENEWAL_PAGE_NAVIGATION {
  REFER_TO_OYEN = 0,
  PREMIUM_NOTICE = 1,
  PLAN = 2,
}

export const TEMP_PREVENTATIVE_CARE_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLSePKiCgMyq0dLi43807v4qV2IEmtlzESq-trK-WFuEKoPVOUQ/viewform';
export const WELLNESS_URL = 'https://www.oyen.my/preventive-care';
export const CI_URL = 'https://www.oyen.my/critical-illness';

export const CI_INSURANCE = {
  STAMP_DUTY_PRICE_ID: 'price_1LuUhgCdDg9GdfRWdx9xX4iN',
  // SST_TAX: 'txr_1ISYSYCdDg9GdfRW66pHl4gT',
  AGE_GROUP: {
    0: {
      P30: 'price_1LushgCdDg9GdfRWU5RlrAiI',
      P50: 'price_1LusjiCdDg9GdfRWtBoAqm8p',
    },
    1: {
      P30: 'price_1LuVZVCdDg9GdfRWomn8oZIB',
      P50: 'price_1LuVakCdDg9GdfRWKWExAmpK',
    },
    2: {
      P30: 'price_1LuVdDCdDg9GdfRWFoi1i2y3',
      P50: 'price_1LuVdxCdDg9GdfRWgt509DO9',
    },
    3: {
      P30: 'price_1LutHNCdDg9GdfRWlFAIvrB4',
      P50: 'price_1LutIHCdDg9GdfRW9FlAK8LY',
    },
    4: {
      P30: 'price_1LuVgnCdDg9GdfRWZ3552lVA',
      P50: 'price_1LxuCCCdDg9GdfRW4lnzeyQl',
    },
    5: {
      P30: 'price_1LuVjICdDg9GdfRWkvw3pF4U',
      P50: 'price_1LuyOlCdDg9GdfRWfy4ZG97c',
    },
    6: {
      P30: 'price_1LuVlqCdDg9GdfRWUhtfFE1N',
      P50: 'price_1LuVmGCdDg9GdfRWkvHLiZ3u',
    },
    7: {
      P30: 'price_1LuVn6CdDg9GdfRW5P9Xhetu',
      P50: 'price_1LuVnfCdDg9GdfRWW6B4f669',
    },
    8: {
      P30: 'price_1LuVoQCdDg9GdfRWLfJ2DGYK',
      P50: 'price_1LuVpGCdDg9GdfRWvNUcuOX2',
    },
    9: {
      P30: 'price_1LuVpjCdDg9GdfRWS0CM2WHt',
      P50: 'price_1LuVq8CdDg9GdfRWRI9MAmWs',
    },
  },
};

export const enum DISPLAYSTATUS {
  SUBMITTED = 0,
  HOLD = 1,
  REVIEWING = 2,
  APPROVED = 3,
  UNSUCCESSFUL = 4,
  PAID = 5,
}

export const CLAIM_HOLD_MSG = [
  {
    day: 0,
    message:
      'We received insufficient information from your claim submission and are currently contacting you/your vet for more information. Please expect some delay in claims processing while we gather these details.',
  },
  {
    day: 5,
    message:
      'It looks like we are still trying to reach you/your vet to obtain more information before processing this claim. Please be informed that your claim will continue to be held until we gather these information.',
  },
];

export const CLAIM_REVIEWING_MSG = [
  {
    day: 0,
    message:
      'Your claim has now been processed and sent to our insurance partner for their processing. Hang in there, we are almost there!',
  },
  {
    day: 3,
    message:
      'It appears that our insurance partner is taking longer than expected to process your claim. Not to worry as we are actively chasing them for the status of your claim. We hope that you and your furkid are doing well!',
  },
  {
    day: 6,
    message:
      'Our sincerest apologies on the delay. We are chasing our insurance partner on your claim status every day. Thank you so much for your patience. If you need any help from us, do reach us at +60162996840 any time.',
  },
  {
    day: 11,
    message:
      "We're continuing to follow up on your claim and will provide updates as soon as we have new information. Thank you for your understanding during this time.",
  },
  {
    day: 15,
    message:
      "We apologize for the delay in processing your claim. Rest assured that we're doing everything we can to get it resolved as quickly as possible.",
  },
  {
    day: 19,
    message:
      "We're currently experiencing a higher than usual volume of claims, which has caused some delays. We're working diligently to get caught up and appreciate your patience in the meantime.",
  },
  {
    day: 23,
    message:
      "We appreciate your patience while we work to get your claim processed. We're committed to keeping you updated throughout the process.",
  },
  {
    day: 27,
    message:
      "We're still working with our insurance partner to process your claim as quickly as possible. We appreciate your patience and understanding.",
  },
  {
    day: 31,
    message:
      "We understand that waiting for your claim to be processed can be frustrating. Please know that we're actively working with our insurance partner to expedite the process.",
  },
  {
    day: 35,
    message:
      'We want to assure you that we are actively working to process your claim and we understand how important it is to you. We are truly sorry for this experience.',
  },
  {
    day: 39,
    message:
      "We're continuing to follow up with our insurance partner and will update your account as soon as we receive any new information about your claim.",
  },
  {
    day: 42,
    message:
      'We are as disappointed as you are 😭 and are doing everything we can to get your claim resolved as quickly as possible. Thank you for your patience during this time.',
  },
  {
    day: 47,
    message:
      'We appreciate your patience while we investigate the delay in your claim processing. Our team is working hard to get this resolved as soon as possible and we will provide updates as soon as we have more information.',
  },
  {
    day: 51,
    message:
      "We know that waiting for your claim to be processed can be stressful, but we're here to help. Rest assured that we're working diligently to get your claim resolved and will provide updates to your account every 4-5 days.",
  },
  {
    day: 55,
    message:
      'We understand that the delay in processing your claim is frustrating, and we apologise for any inconvenience this may have caused.',
  },
  {
    day: 58,
    message:
      "We're sorry that your claim processing so long. We are committed to resolving this issue and are working with our insurance partner to ensure that it gets resolved as soon as possible.",
  },
];

export const NOT_CLAIMABLE_ILLNESSES = [
  'Dental Issues',
  'Cryptorchidism',
  'Accidental Foreign Ingestion',
  'Behaviour Issues',
];

export const SPECIAL_CHARACTER_MAP = {
  '&': ['and', 'ampersand'],
  '@': ['at'],
  $: ['dollar'],
  '#': ['hash', 'number', 'pound'],
  '%': ['percent'],
  '!': ['exclamation', 'bang'],
  '?': ['question'],
  '-': ['dash', 'hyphen'],
  '(': ['open parenthesis', 'open bracket'],
  ')': ['close parenthesis', 'close bracket'],
  '[': ['open square bracket'],
  ']': ['close square bracket'],
  '~': [],
  '`': [],
  '{': [],
  '}': [],
  '|': [],
  '\\': [],
  '/': [],
  '<': [],
  '>': [],
  ',': [],
  '.': [],
  '*': [],
  '^': [],
  ':': [],
  ';': [],
  '"': [],
  "'": [],
  '+': [],
  '=': [],
};

export const namesRegex = /^[~`!@#$%^&*()_+=[\]\\{}|;':",.\\/<>?a-zA-Z0-9- ]+$/;

export const takafulTermsKeys = [
  'takaful_terms_1',
  'takaful_terms_2_link',
  'takaful_terms_3',
  'takaful_terms_4_link',
  'takaful_terms_5',
  'takaful_terms_6_link',
  'takaful_terms_7',
  'takaful_terms_8_popup',
  'takaful_terms_9',
  'takaful_terms_10_link',
  'takaful_terms_11',
];

export const SHOW_NEW_MSIG_POLICY_WORDING =
  process.env.NEXT_PUBLIC_SHOW_NEW_MSIG_POLICY_WORDING === 'true';

// Mapping of plans to their respective order values
export const planOrder: { [key: string]: number } = {
  basic: 1,
  plus: 2,
  champion: 3,
};

// Mapping of rates to their respective order values
export const rateOrder: { [key: string]: number } = {
  '70': 1,
  '80': 2,
  '90': 3,
};

export const disabled_cio_transactional_message_ids = () => {
  const transaction_message_ids = process.env
    .NEXT_PUBLIC_DISABLE_CIO_TRANSACTIONAL_MESSAGE_IDS as string;
  return transaction_message_ids ? transaction_message_ids.split(',') : [];
};

export const SHOW_REPRICING_BANNER =
  process.env.NEXT_PUBLIC_ENABLE_REPRICING_BANNER === 'true';

export const ACTIVE_POLICY_WORDING =
  'https://oyen.s3.ap-southeast-1.amazonaws.com/Policy%20Info/increase-limit/Oyen%20Pet%20Ins%20Policy%20wef%2001.12.24_Final_Eng%20%26%20BM.pdf';

export const ACTIVE_PRODUCT_DISCLOSURE_SHEET =
  'https://oyen.s3.ap-southeast-1.amazonaws.com/Policy%20Info/increase-limit/Oyen%20Pet%20Ins%20PDS%20wef%2001.12.24_Final_Eng%20%26%20BM.pdf';

export const NEXT_PAYMENT_DEBUG_TRACKING_EMAIL =
  process.env.NEXT_PUBLIC_NEXT_PAYMENT_DEBUG_TRACKING_EMAIL || null;

export const TAKAFUL_PDS_EN =
  'https://oyen.s3.ap-southeast-1.amazonaws.com/Policy+Info/GT_Oyen+Takaful+Kucing_PDS_BI_BM_20240301.pdf#page=1';

export const TAKAFUL_PDS_MY =
  'https://oyen.s3.ap-southeast-1.amazonaws.com/Policy+Info/GT_Oyen+Takaful+Kucing_PDS_BI_BM_20240301.pdf#page=4';

export const TAKAFUL_CW_EN =
  'https://oyen.s3.ap-southeast-1.amazonaws.com/Policy+Info/GT_Oyen+Takaful+Kucing_CW_BI_BM_20231120.pdf#page=1';

export const TAKAFUL_CW_MY =
  'https://oyen.s3.ap-southeast-1.amazonaws.com/Policy+Info/GT_Oyen+Takaful+Kucing_CW_BI_BM_20231120.pdf#page=10';
export const DEFAULT_NAMESPACES = ['common'];

export const OYEN_ADDRESS = {
  address: 'Block C, Megan Avenue 1, C-7-3, 189, Jln Tun Razak',
  city: 'Kuala Lumpur',
  state: 'Wilayah Persekutuan Kuala Lumpur',
  zip: '50400',
  country: 'Malaysia',
};

export enum RefNoType {
  PMS = 'PMS',
  V2 = 'V2',
}

export enum PaymentProviders {
  Curlec,
  Adyen,
}

const refNoPatterns = {
  PMS: /^v2[pg]\d*t\d*$/,
  V2: /^\d*M\d*$/,
};

// Configuration for output structure
export const refNoExtractorConfig = {
  // indexes meaning each block of information in the refNo
  // eg: V2P123T123456789
  // index 0: 2
  // index 1: 123
  // index 2: 123456789
  [RefNoType.PMS]: (refNo: string) => {
    const version = refNo.slice(1, 2);
    const policy = refNo.slice(3, refNo.indexOf('t'));
    const timestamp = refNo.slice(refNo.indexOf('t') + 1);
    return { version, policy, timestamp };
  },
  [RefNoType.V2]: (refNo: string) => {
    const policy = refNo.slice(0, refNo.indexOf('M'));
    const timestamp = refNo.slice(refNo.indexOf('M') + 1);
    return { policy, timestamp };
  },
};

export const refNoExtractorWithPrefixConfig = {
  [RefNoType.PMS]: (refNo: string) => {
    const version = refNo.slice(0, 2);
    const policy = refNo.slice(2, refNo.indexOf('t'));
    const timestamp = refNo.slice(refNo.indexOf('t') + 1);
    return { version, policy, timestamp };
  },
  [RefNoType.V2]: (refNo: string) => {
    const policy = refNo.slice(0, refNo.indexOf('M'));
    const timestamp = refNo.slice(refNo.indexOf('M') + 1);
    return { policy, timestamp };
  },
};

export const refNoTypeMapping = {
  // There's couple refNo pattern introduced, when we integrate pms, this is used to split traffic, some to v2 some to pms
  // Two patterns:
  // 1. V2P123T123456789 - PMS
  // 2. 23912M1719295993887 - v2

  [PaymentProviders.Curlec]: {
    [RefNoType.PMS]: refNoPatterns.PMS,
    [RefNoType.V2]: refNoPatterns.V2,
  },
  [PaymentProviders.Adyen]: {
    [RefNoType.PMS]: refNoPatterns.PMS,
    [RefNoType.V2]: refNoPatterns.V2,
  },
};

export const subscriptionAbTestMonthlySampleSize = isNaN(
  parseInt(
    process.env.NEXT_PUBLIC_SUBSCRIPTION_AB_TEST_SAMPLE_SIZE_MONTHLY as string
  )
)
  ? 30
  : parseInt(
      process.env.NEXT_PUBLIC_SUBSCRIPTION_AB_TEST_SAMPLE_SIZE_MONTHLY as string
    );

export const subscriptionAbTestDailySampleSize = isNaN(
  parseInt(
    process.env.NEXT_PUBLIC_SUBSCRIPTION_AB_TEST_SAMPLE_SIZE_DAILY as string
  )
)
  ? 1
  : parseInt(
      process.env.NEXT_PUBLIC_SUBSCRIPTION_AB_TEST_SAMPLE_SIZE_DAILY as string
    );

export const WHATSAPP_REFERRAL_MESSAGE =
  "Hey! I just signed up for Oyen, a pet insurance that's super easy to use. You should check it out too!";

// Prisma doesnt generate enum not in used by db
export enum RemarkSendTo {
  MSIG = 'MSIG',
  ZURICH = 'ZURICH',
  VET = 'VET',
  OYEN = 'OYEN',
  CUSTOMER = 'CUSTOMER',
}

export enum RemarkParty {
  VET = 'VET',
  OYEN = 'OYEN',
  MSIG = 'MSIG',
}

export const pmsTestEmailRegex = /.*oyenpmstest.*@oyen\.my$/;
