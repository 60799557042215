import {
  PolicyStatus,
  PetType,
  PetWeight,
  Sex,
  PetHealthStatus,
  UserPetPolicies,
  Pets,
  PetPolicies,
  PetPoliciesInvoice,
  Users,
} from '@prisma/client';
import { DOG_PLANS, CAT_PLANS } from '@utils/constant';
import dogBasic from '@assets/images/plan/dog-basic.svg';
import dogPlus from '@assets/images/plan/dog-plus.svg';
import dogChampion from '@assets/images/plan/dog-champion.svg';
import catBasic from '@assets/images/plan/cat-basic.svg';
import catPlus from '@assets/images/plan/cat-plus.svg';
import catChampion from '@assets/images/plan/cat-champion.svg';
import dayjs from 'dayjs';
import { IPet } from '../contexts/plan';

const planImage = (value: string) => {
  switch (value) {
    case DOG_PLANS.DOG_BASIC:
      return dogBasic;
    case DOG_PLANS.DOG_PLUS:
      return dogPlus;
    case DOG_PLANS.DOG_CHAMPION:
      return dogChampion;
    case CAT_PLANS.CAT_BASIC:
      return catBasic;
    case CAT_PLANS.CAT_PLUS:
      return catPlus;
    case CAT_PLANS.CAT_CHAMPION:
      return catChampion;
    default:
      throw new Error('Invalid plan');
  }
};

const getRenewalInDays = (
  status: PolicyStatus | undefined | null,
  endDate: any
) => {
  if (
    status !== PolicyStatus.CANCELLED &&
    status !== PolicyStatus.PENDING_CANCEL
  ) {
    return dayjs(endDate).endOf('day').diff(dayjs().endOf('day'), 'day') < 30
      ? dayjs(endDate).endOf('day').diff(dayjs().endOf('day'), 'day')
      : null;
  }
  return null;
};

const getRenewalReadyIds = (userPetPolicies: any[]) => {
  const renewalPolicies = userPetPolicies.filter((p: any) => p.parentPolicyId);

  const renewalApprovedNxPaidIds: number[] = [];

  renewalPolicies.forEach((p: any) => {
    if (p.groupId) {
      const xApprovePolicies = renewalPolicies.filter(
        (r: any) =>
          r.groupId === p.groupId &&
          (r.status !== PolicyStatus.APPROVED || r.transactions.length !== 0)
      );
      if (xApprovePolicies.length === 0) {
        renewalApprovedNxPaidIds.push(p.parentPolicyId);
      }
    } else if (p.status === PolicyStatus.APPROVED) {
      renewalApprovedNxPaidIds.push(p.parentPolicyId);
    }
  });

  return renewalApprovedNxPaidIds;
};

const getPetDetails = (
  policy: UserPetPolicies & {
    pets: Pets;
    petPolicies: PetPolicies;
    users: Users;
    petPoliciesInvoice: PetPoliciesInvoice[];
    parentPolicy: UserPetPolicies;
  },
  statePets: IPet[],
  leads: any,
  newStartDate: Date,
  newEndDate: Date,
  groupId: number
) => {
  const {
    id: userPetPolicyId,
    pets,
    petPolicies,
    users,
    loadingPercent,
    loadingAmount,
    ncdAmount,
    vetMedicalLimit,
    petPoliciesInvoice,
    ncdPercent,
    ncdIsManual,
    ...userPetPolicyDetails
  } = policy;
  const [{ id }] =
    statePets.length > 0
      ? statePets.filter((pet: any) => pet.petName === pets.name)
      : [{ id: undefined }];
  const { healthCondition } = pets;
  // console.log('getPetDetails?', pets.name, pets.dateOfBirth);
  const petDetails = {
    id: id,
    userPetPolicyId: userPetPolicyId,
    petName: pets.name,
    petType: pets.type as PetType,
    petDateOfBirth: dayjs(pets.dateOfBirth).toDate(),
    petSex: pets.sex as Sex,
    petBreed: pets.breed,
    petWeight: pets.weight === null ? undefined : (pets.weight as PetWeight),
    petPhysicallyAble: String(leads?.petPhysicallyAble) || 'true',
    petWellBehaved: String(leads?.petWellBehaved !== null) || 'true',
    petResidence: String(leads?.petResidence) || 'true',
    petMicrochip: String(leads?.petMicrochip !== null) || 'true',
    petVaccinated: String(leads?.petVaccinated !== null) || 'true',
    microchipNumber: pets.microchipId === null ? undefined : pets.microchipId,
    healthStatus: pets.healthStatus as PetHealthStatus,
    healthCondition:
      pets.healthCondition.length === 0 ? undefined : healthCondition,
    congenitalDisease: String(pets.congenitalDisease),
    congenitalDiseaseDetails:
      pets.congenitalDiseaseDetails === null
        ? undefined
        : pets.congenitalDiseaseDetails,
    accident: String(pets.accident),
    accidentDetails:
      pets.accidentDetails === null ? undefined : pets.accidentDetails,
    loadingPercent: loadingPercent,
    loadingAmount: loadingAmount ? loadingAmount / 100 : 0,
    ncdAmount: ncdAmount ? ncdAmount / 100 : 0,
    ncdPercent, // : Math.ceil((ncdAmount / petPolicies.yearlyPrice) * 100),
    ncdIsManual,

    yearlyNcdPercent: 0, // ! TEMP PLACEHOLDER #OI-537
    yearlyNcdAmount: 0, // ! TEMP PLACEHOLDER #OI-537
    startDate: newStartDate,
    endDate: newEndDate,
    groupId: groupId,
    vetMedicalLimit,
    petPoliciesInvoice,
    billCycle: userPetPolicyDetails.billCycle,
    deductiblesAmount: userPetPolicyDetails.deductiblesAmount,
    isBuyback: userPetPolicyDetails.isBuyback,
    policy: {
      id: petPolicies.id,
      plan: petPolicies.name,
      image: planImage(petPolicies.name),
      yearlyPrice: petPolicies.yearlyPrice / 100,
      monthlyPrice: petPolicies.monthlyPrice / 100,
      medicalCost: (petPolicies.vetMedicalLimit ?? 0) / 100,
      overnightStay: petPolicies.maxClinicOvernightStay,
      followUpTreatment: petPolicies.followupTreatmentDays,
      thirdPartyDamages: (petPolicies.thirdPartyLimit ?? 0) / 100,
      cremationCost: (petPolicies.burialLimit ?? 0) / 100,
      freeMicroChip: petPolicies.freeMicroChip,
      deductiblesAmount: policy.deductiblesAmount,
    },
    parentPolicy: policy.parentPolicy,
  };

  return { petDetails, users };
};

export { getRenewalInDays, getRenewalReadyIds, getPetDetails };
