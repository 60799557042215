import { Store } from '@stores/store';
import { petInfoFormSchema } from 'pages/eligibility/v4';
import { petHealthFormSchema } from 'pages/eligibility/v4/health';
import { petMicrochipFormSchema } from 'pages/eligibility/v4/microchip';
import { StateCreator } from 'zustand';
import * as z from 'zod';

interface PetSlice {
  pet: Record<
    string,
    Partial<
      z.infer<
        typeof petInfoFormSchema &
          typeof petHealthFormSchema &
          typeof petMicrochipFormSchema
      >
    >
  >;
  initialPetName: string;
  updatePetName: (oldName: string, newName: string) => void;
  updatePetInfo: (name: string, pet: z.infer<typeof petInfoFormSchema>) => void;
  updatePetHealth: (
    name: string,
    pet: z.infer<typeof petHealthFormSchema>
  ) => void;
  updatePetMicrochip: (
    name: string,
    pet: z.infer<typeof petMicrochipFormSchema>
  ) => void;
  removePet: (name: string) => void;
  setPets: (
    pet: Record<string, Partial<z.infer<typeof petInfoFormSchema>>>
  ) => void;
}

const createPetSlice: StateCreator<Store, [], [], PetSlice> = (set) => {
  return {
    pet: {},
    initialPetName: '',
    setPets: (
      pet: Record<string, Partial<z.infer<typeof petInfoFormSchema>>>
    ) => set({ pet }),
    removePet: (name: string) =>
      set((state) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [name]: removedState, ...otherState } = state.pet;

        if (name === state.initialPetName) {
          return {
            pet: otherState,
            initialPetName: Object.keys(otherState)[0] || '',
          };
        }

        return { pet: otherState };
      }),
    updatePetName: (oldName: string, newName: string) =>
      set((state) => {
        if (oldName === newName) return state;

        const { [oldName]: removedState, ...otherState } = state.pet;
        const { [oldName]: removedPlanState, ...otherPlanState } = state.plan;
        const { [oldName]: removedCoverageState, ...otherCoverageState } =
          state.coverage || {};
        const { [oldName]: removedPricingState, ...otherPricingState } =
          state.pricing || {};

        if (oldName === state.initialPetName) {
          return {
            pet: {
              ...otherState,
              [newName]: {
                ...removedState,
                name: newName,
              },
            },
            plan: {
              ...otherPlanState,
              [newName]: removedPlanState,
            },
            coverage: {
              ...otherCoverageState,
              [newName]: removedCoverageState,
            },
            pricing: {
              ...otherPricingState,
              [newName]: removedPricingState,
            },
            initialPetName: newName,
          };
        }

        // remove old pet name and add new pet name
        delete otherState[oldName];

        return {
          pet: {
            ...otherState,
            [newName]: {
              ...removedState,
              name: newName,
            },
          },
          plan: {
            ...otherPlanState,
            [newName]: removedPlanState,
          },
          coverage: {
            ...otherCoverageState,
            [newName]: removedCoverageState,
          },
          pricing: {
            ...otherPricingState,
            [newName]: removedPricingState,
          },
        };
      }),
    updatePetInfo: (name: string, pet: z.infer<typeof petInfoFormSchema>) =>
      set((state) => {
        if (state.initialPetName === '') {
          return {
            pet: { ...state.pet, [name]: { ...state.pet[name], ...pet } },
            initialPetName: name,
          };
        }

        return {
          pet: { ...state.pet, [name]: { ...state.pet[name], ...pet } },
        };
      }),
    updatePetHealth: (name: string, pet: z.infer<typeof petHealthFormSchema>) =>
      set((state) => ({
        pet: { ...state.pet, [name]: { ...state.pet[name], ...pet } },
      })),
    updatePetMicrochip: (
      name: string,
      pet: z.infer<typeof petMicrochipFormSchema>
    ) =>
      set((state) => ({
        pet: { ...state.pet, [name]: { ...state.pet[name], ...pet } },
      })),
  };
};

export { type PetSlice, createPetSlice };
